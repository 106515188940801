<!-- 成绩查询 -->
<template>
    <div class="result">
        <div class="pass" :class="isPass?'passbg':'notpassbg'">
            <span>{{userInfo.name}}同学</span>
            <span v-if="isPass">恭喜您顺利通过{{$route.query.stage}}，期待您在复赛中取得优异成绩</span>
            <span v-else>很遗憾您未通过{{$route.query.stage}}，不要灰心继续努力！</span>
            <div class="content">
                <img v-if="isPass" src="../../assets/images/active/cup2.png" />
                <img v-else src="../../assets/images/active/grayCup.png" />
                <div class="info">
                    <span>银川市教育委员会</span>
                    <span>双优云桥领导小组办公室</span>
                    <span>2022年01月22日</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isPass:null,
                userInfo:null
            }
        },
        mounted() {
            this.result();
            this.getUserInfo()
        },
        methods: {
            result() {
                let stageId = this.$route.query.stageId;
                this.$Api.Activity.getResult(stageId)
                    .then(res => {
                        console.log('获取成功', res)
                        this.isPass = res.data
                    })
                    .catch(err => {
                        console.log('获取失败', err)
                    })
            },
            getUserInfo(){
                // this.userInfo = JSON.parse(sessionStorage.getItem("userinfo"))
                this.userInfo = this.$store.state.user.userInfo
            }
        }
    };
</script>
<style lang='less' scoped>
    .result {
        min-width: 990px;
        margin: 0 auto;

        /deep/ .el-breadcrumb {
            color: #333;
            margin: 49px 0 32px;
        }

        .passbg {
            background-image: url("../../assets/images/active/pass.png");
        }

        .notpassbg {
            background-image: url("../../assets/images/active/notpass.png");
        }

        .pass {
            height: 559px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 150px;

            span:nth-child(1) {
                color: #333;
                font-size: 24px;
                margin: 171px 0 46px 82px;
                display: inline-block;
            }

            span:nth-child(2) {
                color: #333;
                font-size: 24px;
                // margin-left: 155px;
                text-align: center;
                display: inline-block;
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 93px;

                img {
                    margin-left: 110px;
                    width: 183px;
                    height: 129px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 77px;

                    span {
                        display: inline;
                        margin: 0;
                        font-size: 20px
                    }
                }
            }


        }

        @media screen and (min-width:1250px) {
             min-width: 1200px;
        }
    }
</style>